import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	> .MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(19)};
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		}
	}
	.MuiBadge-root {
		> .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.primary.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(19)};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}
		.MuiBadge-badge {
			top: -4px;
			right: -4px;
			.MuiSvgIcon-root {
				color: ${({ theme }) => theme.palette.primary.contrastText};
				font-size: ${({ theme }) => theme.typography.pxToRem(19)};
			}
		}
	}
`

export { ButtonStyled }
